<template>
  <div class="frontend w-100 d-flex justify-content-center align-items-center vh-100">
    <div
      v-show="$store.state.appConfig.showLoader"
      class="axios-loader"
    >
      <loader />
    </div>
    <!-- <div
        id="user-info-container"
        class="text-white"
      >
        {{ singpassUserInfo }}
      </div> -->
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loader from './Loader.vue'

export default {
  components: {
    Loader,
  },
  data() {
    return {
      singpassUserInfo: {},
    }
  },
  mounted() {
    const formData = new FormData()
    const sessionID = localStorage.getItem('singpassAuthorizationSession')
    formData.append('sessionID', sessionID)
    formData.append('query', JSON.stringify(this.$route.query))

    this.$http.post('front-end/singpass/callback/myinfo', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(response => {
        localStorage.removeItem('singpassAuthorizationSession')
        if (response.data.purpose === 'onboarding') {
          localStorage.setItem('singpassUserInfo', JSON.stringify(response.data.userInfo))
          this.$router.push({ name: 'onboard-form-frontend', params: { token: localStorage.getItem('onboardingToken') }, query: { singpass: true } })
        } else {
          this.singpassUserInfo = response.data.userInfo
          this.$router.push({ name: 'singpass-callback-success' })
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
}
</script>

<style lang="scss">
  ._hj-widget-container {
    display: none !important;
  }
</style>
